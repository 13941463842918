import logo from './assets/img/mindwave1b.svg';
import twitch from './assets/img/icons8-twitch.svg';
import {createUseStyles} from 'react-jss';
import Div100vh from 'react-div-100vh';


export default () => {
  const classes = useStyles();

  return (
      <Div100vh>
        <div className={classes.app}>
          <div className={classes.logoContainer}>
            <img src={logo}
                 alt="logo"
                 className={classes.logo}/>
            <h2 className={classes.tagline}>
              Endless stories.
              <br/>Endless possibilities.
            </h2>
            <p>
              <a href="https://www.twitch.tv/endlestorylive">
                <img alt="Watch on Twitch.TV"
                     src={twitch}/>
              </a>
            </p>
          </div>
        </div>
      </Div100vh>
  );
}

const useStyles = createUseStyles({
  app: {
    background: '#02091f',
    height: '100%',
    width: '100vw',
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
    height: 'auto',
    '@media (min-width: 768px)': {
      width: '60vh',
    },
  },
  logoContainer: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '@media (min-width: 768px)': {
      justifyContent: 'center',
    },
  },
  tagline: {
    color: '#f9efbf',
    fontSize: 'calc(1rem + 1vw)',
    fontFamily: 'Georgia, serif',
    fontWeight: 300,
    fontStyle: 'italic',
    textAlign: 'center',
    lineHeight: 1.2,
    '@media (min-width: 768px)': {
      fontSize: 'calc(1.5rem + 1vw)',
    },
  },
});
